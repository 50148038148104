import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {DEFAULT_REDIRECT, USER_ROLES} from "../configs/AppConfig";

/*
 * This component acts as a gate for normal routes. Each logged in user must have a role that is specified
 * in the guard prop and in case of a producer, must have the relevant type too. If those conditions are not met,
 * the user will get redirected to it's default route.
 * In the same way, a logged in user can't acces any of the auth routes (the login has already been made) and thus, he will
 * get redirected
 *
 * props:
 * guard - (array) represents all roles that can visit the route
 * type - (string) represents the type of producer that can visit the route.
 */
const GuardedRoute = ({component: Component, guard, type, ...rest}) => {
    let user = localStorage.getItem('user');
    if (guard === "auth" && !user)
        return <Route {...rest} render={(props) => <Component {...props} />}/>
    if (user) {
        user = JSON.parse(user)
        return (
            guard.includes(user.role)
                ?
                user.role === 'producer' && type && !user.producer.types.map((item) => item.id).includes(type)
                    ?
                        <Redirect to={USER_ROLES[user.role].redirect}/>
                    :
                    <Route {...rest} render={(props) => (
                        <Component {...props} />
                    )}/>
                :
                <Redirect to={USER_ROLES[user.role].redirect}/>
        )
    } else {
        return (<Redirect to={DEFAULT_REDIRECT}/>)
    }
}

export default GuardedRoute;