import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {ADMIN_PREFIX_PATH} from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route path={`${ADMIN_PREFIX_PATH}/producers`} exact component={lazy(() => import(`./producers`))}/>
                <Route path={`${ADMIN_PREFIX_PATH}/producers/create`} component={lazy(() => import(`./producers/create.js`))}/>
                <Route path={`${ADMIN_PREFIX_PATH}/producers/:id/edit`} component={lazy(() => import(`./producers/edit.js`))}/>
                <Route path={`${ADMIN_PREFIX_PATH}/categories-b2b`} component={lazy(() => import('./categories/categories-b2b'))}/>
                <Route path={`${ADMIN_PREFIX_PATH}/categories-b2c`} component={lazy(() => import('./categories/categories-b2c'))}/>
                <Route path={`${ADMIN_PREFIX_PATH}/reports`} component={lazy(() => import('./reports'))}/>
                <Redirect from={`${ADMIN_PREFIX_PATH}`} to={`${ADMIN_PREFIX_PATH}/home`}/>
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);