import React from 'react'
import {connect} from 'react-redux'
import {Radio, Switch, Button, notification} from 'antd';
import {
    toggleCollapsedNav,
    onNavTypeChange,
    onNavStyleChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onSwitchTheme,
    onDirectionChange
} from 'redux/actions/Theme';
import {LogoutOutlined} from '@ant-design/icons';
import {
    SIDE_NAV_LIGHT,
    NAV_TYPE_TOP,
    SIDE_NAV_DARK,
} from 'constants/ThemeConstant';
import {useHistory} from 'react-router-dom';
import {useThemeSwitcher} from "react-css-theme-switcher";
import axiosRequest from "../../configs/Axios";

const ListOption = ({name, selector, disabled, vertical}) => (
    <div className={`my-4 ${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
        <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
        <div>{selector}</div>
    </div>
)

export const ThemeConfigurator = ({
                                      navType,
                                      sideNavTheme,
                                      navCollapsed,
                                      currentTheme,
                                      toggleCollapsedNav,
                                      onNavStyleChange,
                                      onHeaderNavColorChange,
                                      onSwitchTheme,
                                  }) => {
    const isNavTop = navType === NAV_TYPE_TOP ? true : false
    const isCollapse = navCollapsed

    const {switcher, themes} = useThemeSwitcher();
    const history = useHistory();

    const toggleTheme = (isChecked) => {
        onHeaderNavColorChange('')
        const changedTheme = isChecked ? 'dark' : 'light'
        onSwitchTheme(changedTheme)
        switcher({theme: themes[changedTheme]});
    };

    const logout = () => {
        axiosRequest.post('/logout')
            .then((response) => {
                localStorage.removeItem('user');
                history.push('/auth/login')
            })
            .catch((error) => {
                localStorage.removeItem('user');
                history.push('/auth/login')
            })
    }

    return (
        <>
            <ListOption
                name="Culoare navigatie:"
                selector={
                    <Radio.Group
                        disabled={isNavTop}
                        size="small"
                        onChange={e => onNavStyleChange(e.target.value)}
                        value={sideNavTheme}
                    >
                        <Radio.Button value={SIDE_NAV_LIGHT}>Deschis</Radio.Button>
                        <Radio.Button value={SIDE_NAV_DARK}>Inchis</Radio.Button>
                    </Radio.Group>
                }
                disabled={isNavTop}
            />
            <div className="mb-5">
                <ListOption
                    name="Navigatie restransa:"
                    selector={
                        <Switch
                            disabled={isNavTop}
                            checked={isCollapse}
                            onChange={() => toggleCollapsedNav(!navCollapsed)}
                        />
                    }
                    disabled={isNavTop}
                />
                <ListOption
                    name="Mod intunecat:"
                    selector={
                        <Switch checked={currentTheme === 'dark'} onChange={toggleTheme}/>
                    }
                />
            </div>
            <div>
                <Button icon={<LogoutOutlined/>} block onClick={() => logout()}>
                    <span>Deconectare</span>
                </Button>
            </div>
        </>
    )
}

const mapStateToProps = ({theme}) => {
    const {navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction} = theme;
    return {navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction}
};

const mapDispatchToProps = {
    toggleCollapsedNav,
    onNavTypeChange,
    onNavStyleChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onSwitchTheme,
    onDirectionChange
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeConfigurator)
