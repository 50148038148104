import {createStore, applyMiddleware, compose} from "redux";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducers from "../reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

function configureStore(preloadedState) {

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(persistedReducer, preloadedState, composeEnhancers(
        applyMiddleware(...middlewares)
    ));

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        module.hot.accept("../reducers/index", () => {
            const nextRootReducer = require("../reducers/index");
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

let store = configureStore();
let persistor = persistStore(store);

export {store, persistor}
