import React, {useEffect} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayoutAdmin from "layouts/app-layout-admin";
import AppLayoutProducer from "layouts/app-layout-producer";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import {ADMIN_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_REDIRECT, PRODUCER_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import GuardedRoute from "./GuardedRoute";
import {useThemeSwitcher} from "react-css-theme-switcher";

export const Views = (props) => {
  const { locale, location, direction, currentTheme } = props;
  const {switcher, themes} = useThemeSwitcher();

  const currentAppLocale = AppLocale[locale];

  useEffect(() => {
    switcher({theme: themes[currentTheme]})
  })


  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={DEFAULT_REDIRECT} />
          </Route>
          <GuardedRoute path={AUTH_PREFIX_PATH} guard={'auth'}>
            <AuthLayout direction={direction} />
          </GuardedRoute>
          <GuardedRoute path={ADMIN_PREFIX_PATH} guard={['admin']}>
            <AppLayoutAdmin direction={direction} location={location}/>
          </GuardedRoute>
          <GuardedRoute path={PRODUCER_PREFIX_PATH} guard={['producer']}>
            <AppLayoutProducer direction={direction} location={location}/>
          </GuardedRoute>
          <Redirect to={DEFAULT_REDIRECT} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction, currentTheme } =  theme;
  const { token } = auth;
  return { locale, token, direction, currentTheme }
};

export default withRouter(connect(mapStateToProps)(Views));