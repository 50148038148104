import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'RedPoint';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const ADMIN_PREFIX_PATH = '/admin';
export const PRODUCER_PREFIX_PATH = '/producer'
export const AUTH_PREFIX_PATH = '/auth';
export const DEFAULT_REDIRECT = AUTH_PREFIX_PATH + '/login';
export const B2CTYPEID = 1;
export const B2BTYPEID = 2;

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'ro',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR
};

export const USER_ROLES = {
    admin: {
        label: 'Administrator',
        redirect: ADMIN_PREFIX_PATH + '/reports'
    },
    producer: {
        label: 'Producator',
        redirect: PRODUCER_PREFIX_PATH + '/home'
    }
}