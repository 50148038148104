import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {B2BTYPEID, B2CTYPEID, PRODUCER_PREFIX_PATH} from 'configs/AppConfig'
import GuardedRoute from "../GuardedRoute";

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route
                    path={`${PRODUCER_PREFIX_PATH}/home`}
                    component={lazy(() => import(`./home`))}
                />
                <GuardedRoute
                    path={`${PRODUCER_PREFIX_PATH}/b2b`}
                    component={lazy(() => import('./b2b'))}
                    guard={'producer'}
                    type={B2BTYPEID}
                />
                <GuardedRoute
                    path={`${PRODUCER_PREFIX_PATH}/b2c`}
                    component={lazy(() => import('./b2c'))}
                    guard={'producer'}
                    type={B2CTYPEID}
                />
                <Redirect
                    from={`${PRODUCER_PREFIX_PATH}`}
                    to={`${PRODUCER_PREFIX_PATH}/home`}
                />
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);