import antdRoRo from 'antd/es/locale/ro_RO';
import roMsg from "../locales/ro_RO.json";

const roLang = {
    antd: antdRoRo,
    locale: 'ro-RO',
    messages: {
        ...roMsg
    },
};
export default roLang;
